import React from "react"
import { withStyles } from "@material-ui/core/styles"
import { Box } from "@material-ui/core"
import InputBase from "@material-ui/core/InputBase"
import IconButton from "@material-ui/core/IconButton"
import SearchIcon from "@material-ui/icons/Search"

import { Clear } from "@material-ui/icons"
// import transitions from '@material-ui/core/styles/transitions';

const styles = {
  root: {
    display: "block",
    background: "rgba(125,125,125,0.05)",
    borderRadius: 2,
  },
  input: {
    willChange: "width",
    border: "solid 1px rgba(0,0,0,0.1)",
    "&:hover": {
      background: "rgba(125,125,125,0.03)",
    },
    "& > div > input": {
      willChange: "width",
      width: "150px",
      transition: "width 0.3s",
    },
    "& > div > input:focus": {
      width: "200px",
    },
  },
}

class SearchBar extends React.Component {
  constructor(props) {
    super(props)
    this.state = { searchValue: "" }
    this.handleChange = this.handleChange.bind(this)
    // this.handleSubmit = this.handleSubmit.bind(this);
    this.handleSubmit = e => {
      e.preventDefault()
      console.log("A name was submitted: " + this.state.searchValue)
      this.props.submit(this.state.searchValue)
    }
  }

  handleChange(event) {
    this.setState({ searchValue: event.target.value })
  }

  render() {
    const classes = this.props.classes
    return (
      <Box className={classes.root}>
        <Box
          component="form"
          onSubmit={this.handleSubmit}
          noValidate
          autoComplete="off"
        >
          <Box
            p={0.5}
            display="flex"
            alignItems="center"
            justifyContent="center"
            className={classes.input}
          >
            <IconButton
              size="small"
              aria-label="search"
              onClick={this.handleSubmit}
            >
              <SearchIcon />
            </IconButton>
            <InputBase
              style={{
                fontSize: "1.1rem",
              }}
              fullWidth
              placeholder="Search Paper"
              inputProps={{ "aria-label": "search paper" }}
              name="search value"
              value={this.state.searchValue}
              onChange={this.handleChange}
            />
            {this.state.searchValue ? (
              <IconButton
                size="small"
                aria-label="clear"
                onClick={e => {
                  this.handleChange({ target: { value: "" } })
                  this.props.submit("")
                }}
                children={<Clear />}
              />
            ) : (
              <></>
            )}
          </Box>
        </Box>
      </Box>
    )
  }
}

export default withStyles(styles)(SearchBar)
